.aircrew-members-list {
  background-color: transparent;
  & .mat-expansion-panel-header .mat-expansion-panel-header-title {
    margin: 0;
  }

  & .mat-expansion-panel-body {
    padding: 0 !important;
    background-color: transparent !important;
  }
}

.mat-expansion-panel {
  background-color: transparent !important;
}
