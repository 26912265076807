.cal-month-view {
  background-color: var(--color-event-calendar);

  .cal-header {
    align-items: center;
    color: var(--color-grey-dark-3);
    font-size: 1.2rem;
    font-weight: 500;
    height: 2rem;
    pointer-events: none;
    width: 100%;
  }

  .cal-days {
    // necessary the negative margin to see the days' names inside the month's cells
    margin-top: -2rem;

    @include width(lt-sm) {
      border: 0;
      margin-top: 0;
      padding: 0.5rem;
    }
  }

  .cal-day-cell {
    height: calc(20vh - 12px);
    overflow-x: hidden;

    // Mobile style
    @include width(lt-sm) {
      overflow: hidden;
      height: calc(20vh - 36px);
    }
  }

  .cal-cell-top {
    min-height: 1.9rem;
  }

  .cal-day-number {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 0.8rem;
    margin-top: 0;
    opacity: 1;

    // Mobile style
    @include width(lt-sm) {
      font-weight: 500;
    }
  }

  .cal-day-cell.cal-weekend .cal-day-number {
    color: var(--color-black);
  }

  .cal-day-cell.cal-out-month .cal-day-number {
    color: var(--color-grey-dark-3);
    opacity: 1;
  }

  .cal-day-cell.cal-today .cal-day-number {
    font-size: 1rem;
  }

  .cal-day-badge {
    visibility: hidden;
  }

  .cal-event {
    border-radius: 0.5rem;
    height: 2rem;
    width: 100%;
  }

  // setting the cursor to stay unchanged while hovering the cells with events on it
  .cal-day-cell.cal-in-month.cal-has-events {
    cursor: initial;
  }

  // setting the background color of the calendar cell to stay the same while being hover by cursor
  .cal-cell-row .cal-cell:hover {
    background-color: initial;
  }

  // setting off state for events out of month
  .cal-out-month > div {
    opacity: 0.4;
    pointer-events: none;
  }
}

.event-color {
  // Ground
  &__ground {
    background-color: var(--color-grey-light-3);
    border: 0.2rem solid var(--ground);

    .event-text {
      color: var(--ground);
    }
  }

  &__ground.is-full {
    background-color: var(--ground);

    .event-text {
      color: var(--color-white);
    }

    .event-request {
      color: var(--color-white);
    }
  }

  // Sim
  &__sim {
    background-color: var(--color-grey-light-3);
    border: 0.2rem solid var(--sim);

    .event-text {
      color: var(--sim);
    }
  }

  &__sim.is-full {
    background-color: var(--sim);

    .event-text {
      color: var(--color-white);
    }

    .event-request {
      color: var(--color-white);
    }
  }

  // Flight
  &__flight {
    background-color: var(--color-grey-light-3);
    border: 0.2rem solid var(--flight);

    .event-text {
      color: var(--flight);
    }
  }

  &__flight.is-full {
    background-color: var(--flight);

    .event-text {
      color: var(--color-white);
    }

    .event-request {
      color: var(--color-white);
    }
  }

  // TDY
  &__tdy {
    background-color: var(--color-grey-light-3);
    border: 0.2rem solid var(--tdy);

    .event-text {
      color: var(--tdy);
    }
  }

  &__tdy.is-full {
    background-color: var(--tdy);

    .event-text {
      color: var(--color-white);
    }

    .event-request {
      color: var(--color-white);
    }
  }
  // Alert Line
  &__alertline {
    background-color: var(--color-grey-light-3);
    border: 0.2rem solid var(--color-button-event-alertline);

    .event-text {
      color: var(--color-button-event-alertline);
    }
  }

  &__alertline.is-full {
    background-color: var(--color-button-event-alertline);

    .event-text {
      color: var(--color-white);
    }

    .event-request {
      color: var(--color-white);
    }
  }
}

/**
 This is so the app occupy the full height of the page, and removes
 mat-drawer default color (yellow-ish)
**/
.mat-drawer-container {
  @include width(lt-sm) {
    background-color: var(--color-white);
    height: 100%;
  }
}

/* Mobile calendar styles */
.cal-month-view .cal-day-cell:not(:last-child) {
  @include width(lt-sm) {
    border-right: 0;
  }
}

.cal-month-view .cal-days .cal-cell-row {
  @include width(lt-sm) {
    border-bottom: 0;
  }
}

.cal-week-view .cal-current-time-marker {
  background-color: transparent;
}

.uta-day {
  padding: 0.1rem 0.4rem;
  background-color: var(--color-accent-dark);
  border-radius: 0.5rem;
  // Used to override specificity in month and week views
  color: var(--color-white) !important;
  cursor: pointer;
}

.proflyt-picker {
  .mat-calendar-body-cell {
    font-size: 1.4rem !important;
  }
  .mdc-button .mdc-button__label {
    font-size: 1.4rem !important;
  }
}
