.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate {
  background-color: var(--color-accent) !important;
  color: var(--color-white) !important;
  // display: none !important;
}

.pf-radio.mat-mdc-list-option
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--color-accent);
  border-color: var(--color-accent, --color-accent);
}

.pf-radio.mat-mdc-list-option
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--color-accent);
  border-color: var(--color-accent, --color-accent);
}

.pf-checkbox.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

.pf-checkbox.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: var(--color-white);
}

.pf-checkbox.mat-mdc-list-option
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--color-accent);
}

.pf-checkbox.mat-mdc-list-option
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--color-accent);
}
