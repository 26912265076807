.mat-drawer-container {
  height: 100%;
  z-index: 0 !important;
}

.mat-drawer-content {
  background: var(--color-new-secondary);
}

.mat-drawer.mat-drawer-end {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
