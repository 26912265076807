// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();

$proflyt-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$azure-palette,
    ),
    typography: (
      brand-family: var(--font-family),
      plain-family: var(--font-family),
      bold-weight: 600,
      medium-weight: 500,
      regular-weight: 400,
    ),
  )
);

// Wrap the theme in a root selector
html {
  @include mat.all-component-themes($proflyt-theme);
  font-size: var(--default-font-size);
}
