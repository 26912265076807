.payment-dialog-list-option {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    text-align: center;
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 1.4rem !important;
}

.mat-mdc-list-item .mdc-list-item__primary-text {
  font-size: 1.4rem !important;
}
