/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Material Styles
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'scss/themes/app-material-custom-theme';

// Vendor Styles
@import 'scss/vendors/my-vendor-file';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

// proflyt Styles
@import 'scss/abstracts/variables/variables';
@import 'scss/abstracts/responsive-width-mixins';
@import 'scss/abstracts/responsive-device-mixins';
@import 'scss/abstracts/responsive-height-mixins';

@import 'scss/base/base';
@import 'scss/base/animations';
@import 'scss/base/typography';
@import 'scss/base/utilities';

@import 'scss/components/app-mat-button';
@import 'scss/components/app-mat-calendar';
@import 'scss/components/app-mat-icon';
@import 'scss/components/pro-form-components';
@import './scss/components/pro-angular-calendar';
@import './scss/components/pro-angular-calendar';
@import './scss/components/pro-angular-calendar-week-view';
@import './scss/components/pro-id-chip';
@import './scss/components/pro-mat-table';
@import './scss/components/app-mat-time-picker';
@import './scss/components/app-mat-progress-bar';
@import './scss/components/app-angular-calendar';
@import './scss/components/app-mat-slide-toggle';
@import './scss/components/app-mat-checkbox';
@import './scss/components/app-mat-drawer';
@import './scss/components/app-mat-list';
@import './scss/components/app-mat-dialog';
@import './scss/components/app-mat-header';
@import './scss/components/app-mat-table';
@import './scss/components/app-mat-autocomplete';
@import './scss/components/pro-scrollbar';
@import './scss/components/datepicker-theme';
@import './scss/components/pro-scrollbox';
@import 'scss/components/app-mat-badge';
@import 'scss/components/app-mat-slider';
@import 'scss/components/app-mat-button-toggle-group';
@import 'scss/components/app-mat-expansion-panel';
@import 'scss/components/pro-event';
@import 'scss/components/app-mat-tooltip';
// This will fix black screen on app start because there are not ion components
html,
body {
  background-color: var(--color-accent);
}

html:not(.hydrated) body {
  display: initial !important;
}

// Fixes
@import './scss/fixes/ios-notch-fixes.scss'; /* stylelint-disable-line */
