/* stylelint-disable selector-type-no-unknown */
.dialog-policy-condition mat-dialog-container {
  overflow: visible;
  padding: 0;
}

.dialog-padding-0 .mat-mdc-dialog-container {
  padding: 0;
}

.my-custom-dialog-class .mat-mdc-dialog-container {
  overflow: inherit;
}

.mat-mdc-dialog-surface {
  border-radius: 10px !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  font-size: 1.4rem !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 1.8rem !important;
}
