/* stylelint-disable selector-type-no-unknown */

.table-wrapper {
  height: 100%;
  padding: 2rem;
}

.table-container {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-table {
    background: var(--color-new-secondary);
  }

  .mat-mdc-header-row {
    border-bottom-color: var(--color-new-grey-dark-2);

    .mat-mdc-header-cell {
      border-bottom-color: var(--color-new-grey-dark-2);
      border-left: 0;
      color: var(--color-new-grey-dark-1);
      font-size: 1.3rem;
    }
  }

  .mat-mdc-row {
    border-bottom-color: var(--color-new-grey-dark-2);
    height: 50px;

    &:nth-child(2n) {
      background-color: var(--color-new-primary);
    }

    &:nth-child(2n + 1) {
      background-color: var(--color-new-secondary);
    }

    &:last-child {
      border-bottom-color: transparent;
    }

    .mat-mdc-cell {
      color: var(--color-new-grey-dark-3);
      font-size: 1.2rem;
    }
  }
}

.mat-mdc-header-cell.squadron-header-cell:first-of-type,
.mat-mdc-cell.squadron-mat-cell:first-of-type {
  padding-left: 2rem;

  @include width(lt-sm) {
    padding-left: 1rem;
  }
}

.table-data .mat-sort-header-container {
  justify-content: center;
}

.table-data th.mat-mdc-header-cell:first-of-type,
.table-data td.mat-mdc-cell:first-of-type,
.table-data td.mat-mdc-footer-cell:first-of-type {
  padding-left: 0.8rem;
}

.table-data th.mat-mdc-header-cell:last-of-type,
.table-data td.mat-mdc-cell:last-of-type,
.table-data td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0.8rem;
}
